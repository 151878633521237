import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Localization`}</h1>
    <p>{`NDS components include some default strings for commonly used labels and aria-labels.`}</p>
    <p>{`To pass a locale to NDSProvider, locate the NDSProvider within your app and pass in the locale prop.`}</p>
    <p><inlineCode parentName="p">{`<NDSProvider locale={yourLocale} />`}</inlineCode></p>
    <p>{`The default language used by these strings is English (US) unless a different locale is passed to the NDSProvider. All strings within NDS can be overridden using the component's props.`}</p>
    <p>{`To see an example of a localized component, go to the `}<a parentName="p" {...{
        "href": "https://master--5f60c6c285eaad0022dce67f.chromatic.com/?path=/story/components-pagination--pagination"
      }}>{`Pagination`}</a>{` story and in the "Knobs" section you should be able to select an NDS Provider Locale from a dropdown and observe the labels translate according to the selected locale.`}</p>
    <h2>{`Available NDS Locales`}</h2>
    <p>{`Below is a list of all locales currently accepted by NDSProvider. Use the "Locale Prop Value" as the string value to pass to NDSProvider's locale prop.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Language`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Locale Prop Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`German (Germany)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`de_DE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`English (US)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en_US`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish (Mexico)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`es_MX`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`France (French)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fr_FR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dutch (Netherlands)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nl_NL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Polish (Poland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pl_PL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portuguese (Brazil)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pt_BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Romanian (Romania)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ro_RO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified Chinese (China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`zh_CN`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      